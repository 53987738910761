// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enquiry-form {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    margin: 20px auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .enquiry-form h3 {
    font-size: 20px;
    color: #333333;
    margin-bottom: 15px;
  }
  
  .enquiry-form input,
  .enquiry-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .enquiry-form textarea {
    height: 80px;
    resize: none;
  }
  
  .enquiry-form button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .enquiry-form button:hover {
    background-color: #45a049;
  }
  
  .enquiry-form .close {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .enquiry-form .close:hover {
    background-color: #e53935;
  }
  
  .enquiry-form .thank-you-message {
    font-size: 16px;
    color: #333333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/EnquiryForm/EnquiryForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;;IAEE,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,cAAc;EAChB","sourcesContent":[".enquiry-form {\n    background-color: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    width: 300px;\n    margin: 20px auto;\n    text-align: center;\n    font-family: Arial, sans-serif;\n  }\n  \n  .enquiry-form h3 {\n    font-size: 20px;\n    color: #333333;\n    margin-bottom: 15px;\n  }\n  \n  .enquiry-form input,\n  .enquiry-form textarea {\n    width: calc(100% - 20px);\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #dddddd;\n    border-radius: 4px;\n    font-size: 14px;\n  }\n  \n  .enquiry-form textarea {\n    height: 80px;\n    resize: none;\n  }\n  \n  .enquiry-form button {\n    background-color: #4CAF50;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .enquiry-form button:hover {\n    background-color: #45a049;\n  }\n  \n  .enquiry-form .close {\n    background-color: #f44336;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n    margin-top: 10px;\n  }\n  \n  .enquiry-form .close:hover {\n    background-color: #e53935;\n  }\n  \n  .enquiry-form .thank-you-message {\n    font-size: 16px;\n    color: #333333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
