export const questions = {
  "Learning Path": [
    { id: 1, text: "What is your current level of experience?", type: 'dropdown', options: ['Beginner', 'Intermediate', 'Advanced'] },
    { id: 2, text: "How much time can you dedicate weekly to learning?", type: 'dropdown', options: ['Less than 5 hours', '5-10 hours', 'More than 10 hours'] },
    { id: 3, text: "Preferred learning format?", type: 'dropdown', multiple: true, options: ['Videos', 'Articles', 'Interactive Exercises', 'Podcasts', 'Workshops'] },
    { id: 4, text: "What are your career goals?", type: 'text' },
    { id: 5, text: "Are you interested in learning programming languages?", type: 'dropdown', options: ['Yes', 'No'] },
    { id: 6, text: "What type of projects interest you?", type: 'dropdown', multiple: true, options: ['Web Development', 'Mobile Development', 'Data Science', 'Machine Learning', 'Cybersecurity', 'Game Development', 'AI/ML', 'Blockchain', 'IoT', 'DevOps', 'None of the above'] },
    { id: 7, text: "Do you have any preferred learning platforms?", type: 'dropdown', multiple: true, options: ['Coursera', 'Udemy', 'edX', 'Pluralsight', 'Khan Academy', 'LinkedIn Learning', 'YouTube', 'FreeCodeCamp', 'Codecademy', 'None'] },
    { id: 8, text: "Do you need certification?", type: 'dropdown', options: ['Yes', 'No', 'Not Sure'] },
    { id: 9, text: "What is your preferred learning style?", type: 'dropdown', multiple: true, options: ['Self-paced', 'Instructor-led', 'Group learning', 'Peer learning', 'Mentorship'] },
    { id: 10, text: "What is your budget for learning?", type: 'dropdown', options: ['Free', 'Under $50', 'Under $100', 'No budget limit'] },
    { id: 11, text: "Are there specific topics or skills you want to learn about?", type: 'text' },
    { id: 12, text: "Do you have any preferred resources or links for learning?", type: 'text' },
  ],
  "Skill Assessment": [
    { id: 1, text: "Which area do you want to assess?", type: 'dropdown', multiple: true, options: ['Web Development', 'Data Science', 'Machine Learning', 'Cybersecurity', 'Cloud Computing', 'DevOps', 'Mobile Development', 'AI/ML', 'Blockchain', 'Networking'] },
    { id: 2, text: "What is your current proficiency level?", type: 'dropdown', options: ['Beginner', 'Intermediate', 'Advanced'] },
    { id: 3, text: "How often do you practice your skills?", type: 'dropdown', options: ['Daily', 'Weekly', 'Monthly', 'Rarely'] },
    { id: 4, text: "What type of assessment do you prefer?", type: 'dropdown', multiple: true, options: ['Multiple Choice', 'Coding Challenges', 'Projects', 'Quizzes', 'Peer Review'] },
    { id: 5, text: "What is your goal for this assessment?", type: 'dropdown', multiple: true, options: ['Identify strengths and weaknesses', 'Prepare for a job interview', 'Get certified', 'Self-evaluation'] },
    { id: 6, text: "Do you have experience with online assessments?", type: 'dropdown', options: ['Yes', 'No', 'Somewhat'] },
    { id: 7, text: "How do you handle feedback?", type: 'dropdown', options: ['Positively', 'Negatively', 'Neutral', 'Constructively'] },
    { id: 8, text: "Do you need feedback on your assessment?", type: 'dropdown', options: ['Yes', 'No'] },
    { id: 9, text: "Are you looking for timed assessments?", type: 'dropdown', options: ['Yes', 'No', 'No preference'] },
  ],
  "Project Builder": [
    { id: 1, text: "What type of project are you interested in?", type: 'dropdown', multiple: true, options: ['Web Application', 'Mobile Application', 'Data Analysis', 'Machine Learning Model', 'Game Development', 'IoT Project', 'Blockchain Application', 'AI/ML Project', 'Cloud Computing', 'DevOps Pipeline'] },
    { id: 2, text: "What is your current skill level?", type: 'dropdown', options: ['Beginner', 'Intermediate', 'Advanced'] },
    { id: 3, text: "What is the goal of your project?", type: 'text' },
    { id: 4, text: "Do you have a preferred programming language for this project?", type: 'dropdown', multiple: true, options: ['Python', 'JavaScript', 'Java', 'C++', 'C#', 'Ruby', 'Go', 'Swift', 'Rust', 'Kotlin'] },
    { id: 5, text: "What is your timeline for this project?", type: 'dropdown', options: ['Less than a month', '1-3 months', 'More than 3 months'] },
    { id: 6, text: "Do you need resources or templates?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 7, text: "Do you have a specific audience for your project?", type: 'text' },
    { id: 8, text: "Do you want to collaborate with others?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 9, text: "Do you need mentorship for this project?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 10, text: "Will this project be open source?", type: 'dropdown', options: ['Yes', 'No', 'Not Sure'] },
    { id: 11, text: "Are there specific resources or tools you would like to use?", type: 'text' },
  ],
  "Resource Library": [
    { id: 1, text: "Preferred resource type?", type: 'dropdown', multiple: true, options: ['Books', 'Articles', 'Video Tutorials', 'Online Courses', 'Webinars', 'Podcasts', 'Cheat Sheets'] },
    { id: 2, text: "Preferred language?", type: 'dropdown', options: ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese', 'Other'] },
    { id: 3, text: "What topics are you interested in?", type: 'text' },
    { id: 4, text: "Do you prefer beginner, intermediate, or advanced resources?", type: 'dropdown', options: ['Beginner', 'Intermediate', 'Advanced'] },
    { id: 5, text: "Do you need free or paid resources?", type: 'dropdown', options: ['Free', 'Paid', 'No preference'] },
    { id: 6, text: "Do you prefer text-based or video-based resources?", type: 'dropdown', options: ['Text-based', 'Video-based', 'No preference'] },
    { id: 7, text: "What is your learning goal?", type: 'dropdown', multiple: true, options: ['Gain new skills', 'Deepen existing knowledge', 'Prepare for certification', 'Career advancement'] },
    { id: 8, text: "Do you need resources in a specific format?", type: 'dropdown', multiple: true, options: ['PDF', 'ePub', 'Kindle', 'HTML'] },
    { id: 9, text: "Do you prefer short or long resources?", type: 'dropdown', options: ['Short (less than 1 hour)', 'Medium (1-3 hours)', 'Long (more than 3 hours)', 'No preference'] },
    { id: 10, text: "Are there specific resources or links you have found useful?", type: 'text' },
  ],
  "Career Advice": [
    { id: 1, text: "What is your current job role?", type: 'dropdown', options: ['Student', 'Entry-level Professional', 'Mid-level Professional', 'Senior Professional', 'Freelancer', 'Entrepreneur'] },
    { id: 2, text: "What are your career aspirations?", type: 'text' },
    { id: 3, text: "Preferred advice format?", type: 'dropdown', multiple: true, options: ['Articles', 'Videos', 'Mentorship', 'Webinars', 'Workshops'] },
    { id: 4, text: "Do you need advice on switching careers?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 5, text: "Do you need help with resume writing?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 6, text: "Are you looking for job interview tips?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 7, text: "Do you need advice on networking?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 8, text: "What is your preferred industry?", type: 'dropdown', multiple: true, options: ['Technology', 'Finance', 'Healthcare', 'Education', 'Consulting', 'Other'] },
    { id: 9, text: "Do you have a target job role in mind?", type: 'text' },
    { id: 10, text: "Are you looking for advice on work-life balance?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 11, text: "Do you need tips on remote work?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
    { id: 12, text: "Do you need advice on salary negotiation?", type: 'dropdown', options: ['Yes', 'No', 'Maybe'] },
  ],
};