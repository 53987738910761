// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page {
    padding: 20px;
  }
  
  .admin-page h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Admin/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".admin-page {\n    padding: 20px;\n  }\n  \n  .admin-page h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
