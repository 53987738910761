// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.auth-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.auth-form label {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #333;
}

.auth-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.auth-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.auth-button:hover {
  background-color: #0056b3;
}

.error {
  margin-top: 1rem;
  color: #ff0000;
  font-size: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/Auth.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".auth-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f4f4f4;\n}\n\n.auth-form {\n  background: #fff;\n  padding: 2rem;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  max-width: 400px;\n  width: 100%;\n}\n\n.auth-form label {\n  display: block;\n  margin-bottom: 1rem;\n  font-size: 1.2rem;\n  color: #333;\n}\n\n.auth-input {\n  width: 100%;\n  padding: 0.75rem;\n  margin-bottom: 1rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 1rem;\n}\n\n.auth-button {\n  padding: 0.75rem 1.5rem;\n  border: none;\n  border-radius: 4px;\n  background-color: #007bff;\n  color: #fff;\n  font-size: 1rem;\n  cursor: pointer;\n}\n\n.auth-button:hover {\n  background-color: #0056b3;\n}\n\n.error {\n  margin-top: 1rem;\n  color: #ff0000;\n  font-size: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
